import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '../media-queries';

const Wrapper = styled.main`
    width: 100%;
    z-index: 99;
    background-color: #ffffffcf;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* cursor: url(/images/left_arrow.cur), auto;
    cursor: url('/images/left_arrow.cur') 0 17, auto;
    cursor: -webkit-image-set(
        url('/images/left_arrow.cur') 1x,
        url('/images/left_arrow.cur') 2x
    ) 0 17, auto; */

    #container {
        cursor: default;
        display: flex;
        flex-flow: column;
        position: relative;

        align-items: center;
        justify-content: center;

        width: 70vw;
        height: 39vw;
        object-fit: contain;

        ${devices.mobile} {
            width: 100vw;
            height: 60vw;
        }

        iframe {
            width: 100%;
            height: auto;
        }
    }

`

const Showreel = () => {

    const [display, setDisplay] = useState(false);
    const toggleDisplay = () => {
        setDisplay(!display);
    }


    const navigate = useNavigate();
    return <Wrapper
        onClick={() => {
            navigate('/')
        }}
    >
        <div id="container" onClick={(e) => e.stopPropagation()}>
            <ReactPlayer width="100%" height="100%" controls={true} url={"https://player.vimeo.com/video/1059682709?h=733f821a53"} style={{zIndex: 3}} playing={true} />
        </div>

    </Wrapper>
}


export default Showreel;