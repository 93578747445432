import { Swiper, SwiperSlide } from 'swiper/react';
// @ts-ignore
import { Pagination, Navigation } from 'swiper/core';
// @ts-ignore
import ReactPlayer from 'react-player';
import styled from "styled-components";
import { useSwipeable } from 'react-swipeable';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useMatch, useNavigate } from 'react-router-dom';
import { useMediaQuery, useOnClickOutside } from 'usehooks-ts';
import { useSelector } from 'react-redux';
import { getCountries } from '../reducers/settings-reducer';
import { getMediaType, MediaType } from '../config';
import { device, devices } from '../media-queries';
import React from 'react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export const StyledProject = styled.div`
    #SwiperContainer {
        width: 100%;
        /* height: 40vw; */
        aspect-ratio: 16/9;
        display: flex;
        max-height: 80vh;

        .swiper-pagination-bullet-active {
            background-color: #000 !important;
        }
        .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after,
        .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
            color: black;
            zoom: 0.8;
        }
        .swiper-button-prev,
        .swiper-button-next {
            position: fixed;    
        }

        .swiper-button-next {
            ${devices.mobile} {
                right: 0;
                transform: none;
            }
            right: 15vw;
            transform: translateX(100%);
        }
        
        .swiper-button-prev {
            ${devices.mobile} {
                left: 0;
                transform: none;
            }
            left: 15vw;
            transform: translateX(-100%);
        }

        .swiper-pagination {
            scale: 0.8;
        }

        max-width: 70vw;
        ${devices.mobile} {
            max-width: 100vw;
            width: 100vw;
        }

        .swiper-slide {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        
    }
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fffffff0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11;


    #nextArrow {
        position: absolute;
        width: 1rem;
        padding: 4.5rem;
        right: -6rem;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        ${devices.mobile} {
            padding: 1rem;
            right: -2rem;
            display: none !important;
        }
    }
    #prevArrow {
        position: absolute;
        width: 1rem;
        padding: 4.5rem;
        left: -6rem;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        ${devices.mobile} {
            padding: 1rem;
            right: -2rem;
            display: none !important;
        }
    }

    /* cursor: url(/images/left_arrow.cur), auto;
    cursor: url('/images/left_arrow.cur') 0 17, auto;
    cursor: -webkit-image-set(
        url('/images/left_arrow.cur') 1x,
        url('/images/left_arrow.cur') 2x
    ) 0 17, auto; */



    #container {
        width: 70vw;
        max-height: 80vh;
        cursor: default;
        display: flex;
        flex-flow: column;
        position: relative;
        justify-content: center;

        display: flex;
        align-items: center;
        ${devices.mobile} {
            width: 100vw;
        }

        .header {
            ${devices.mobile} {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: auto;
                transform: translateY(-100%);
                .author, .title {
                    transform: unset !important;
                    position: relative !important;
                }
            }
            .author {
                z-index: 9;
                position: absolute;
                left: 0;
                transform: translateY(-100%);
                font-family: 'Neue Haas Grotesk Display Pro';
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                ${devices.mobile} {
                    font-size: 1.5rem;
                    /* position: relative; */
                }
                ${devices.tablet} {
                    font-size: 1.2rem;
                }
                line-height: 29px;
                /* identical to box height */
                text-transform: uppercase;
                color: #000000;
            }

            .close {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 2rem;
                z-index: 99;
                display: none;
                ${devices.mobile} {
                    display: block;
                }
            }
            
            .title {
                z-index: 9;
                position: absolute;
                right: 0;
                transform: translateY(-100%);

                font-family: 'Neue Haas Grotesk Display Pro';
                font-style: normal;
                font-weight: 600;
                font-size: 2rem;
                ${devices.mobile} {
                    font-size: 1.3rem;
                    /* position: relative; */
                    color: #b0b0b0;
                }
                ${devices.tablet} {
                    font-size: 1.2rem;
                }

                line-height: 29px;
                /* identical to box height */
                
                text-transform: uppercase;
                
                color: #000000;
            }
        }

        .credits {
            font-family: 'Neue Haas Grotesk Display Pro';
            font-style: normal;
            font-weight: 600;
            font-size: 1.7rem;
            ${devices.mobile} {
                font-size: 1.5rem;
            }
            ${devices.tablet} {
                    font-size: 1.2rem;
            }
            line-height: 29px;
            text-transform: uppercase;
            cursor: pointer;
            position: absolute;
            bottom: -2rem;
            left: 0;

            color: #b0b0b0;
        }

        .creditsText {
            font-family: 'Neue Haas Grotesk Display Pro';
            font-style: normal;
            font-weight: 700;
            font-size: 1.6vw;
            text-transform: uppercase;
            height: 100vh;
            background: #ffffffbd;
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            line-height: 0.3rem;
            display: flex;
            z-index: 999;

            cursor: url(/images/left_arrow.cur), auto;
            cursor: url('/images/left_arrow.cur') 0 17, auto;
            cursor: -webkit-image-set(
            url('/images/left_arrow.cur') 1x,
            url('/images/left_arrow.cur') 2x
            ) 0 17, auto;

            
            ${devices.mobile} {
                font-size: 4vw;
                line-height: 1.3rem;
            }
            .lines {
                cursor: pointer;
                display: flex;
                flex-flow: column;

                max-height: 80vh;
                overflow: auto;
                
                
                height: auto;
                margin-top: auto;
                margin-bottom: auto;
                align-self: center;
                justify-content: flex-start;
                /* width: 100%; */
                width: 80vw;
                margin-left: 10vw;
                gap: 1rem;
                .line {
                    display: flex;
                    gap: 1rem;
                    align-items: center;
                    width: 100%;
                    align-items: flex-start;
                    .left {
                        width: 50%;
                        text-align: right;
                        margin: 0;
                        line-height: 2.5vw;
                        ${devices.mobile} {
                            font-size: 0.8rem;
                            line-height: 1rem;
                        }
                    }
                    .right {
                        width: 50%;
                        text-align: left;
                        margin: 0;
                        line-height: 2.5vw;
                        
                        ${devices.mobile} {
                            font-size: 0.8rem;
                            line-height: 1rem;
                        }
                    }
                }
            }
        }
    }

    .carousselWrapper {
        position: relative;
        /* max-width: 90vw; */
        /* max-height: 90vh; */
        display: flex;
        flex: 1;
    }

    #dots {
        position: absolute;
        display: none;
        ${devices.mobile} {
            display: flex;
        }
        gap: 0.5rem;
        bottom: -1.5rem;
        .dot {
            width: 6px;
            height: 6px;
            border-radius: 7px;
            background: black;
            cursor: pointer;
            border: 2px solid black;
        }
    }


`

const SvgLoadingW = styled.div`
width: 100%;
height: 100%;
position: fixed;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
`

const SvgLoading = (props: any) => (
    <SvgLoadingW>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            viewBox="0 0 100 100"
            {...props}
        >
            <path
                fill="#000"
                d="M73 50c0-12.7-10.3-23-23-23S27 37.3 27 50m3.9 0c0-10.5 8.5-19.1 19.1-19.1S69.1 39.5 69.1 50"
            >
                <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    dur="1s"
                    from="0 50 50"
                    repeatCount="indefinite"
                    to="360 50 50"
                    type="rotate"
                />
            </path>
        </svg>
    </SvgLoadingW>
)

const StyledMedias = styled.div`
    display: flex;
    /* flex-flow: row; */
    /* overflow: scroll; */
    max-width: 100%;
    max-height: 100%;

    /* allow swipe... */
    ${devices.mobile} {

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 70%;
            left: 0;
            top: 0;
        }
    }
    justify-content: center;
    video, img, iframe {
        max-height: 80vh;
        max-width: 80vw;
        ${devices.mobile} {
            max-height: 80vh;
            max-width: calc(100vw - 1rem);
        }
    }

    /* img {
        width: 100%;
        height: auto;
    }
    video {
        width: 100%;
        height: auto;
    }
    iframe {
        width: 100%;
        height: auto;
    } */
`
export const EmbedSlide = ({ url, isSelected, width }: { url: string; isSelected?: boolean, width: string }) => (
    <ReactPlayer width={width} height="100%" url={url} controls={true} autoplay playing={isSelected} />
);



const Project = (props: { projectId: string }) => {


    const projects = useSelector(getCountries)
    const project = projects.find(p => p._id == props.projectId);
    const navigate = useNavigate();
    const projectContainerRef = useRef<HTMLDivElement | null>(null)
    const [instanciated, setInstanciated] = useState(false);
    const handleClickOutside = () => {
        navigate("/")
    }
    useOnClickOutside(projectContainerRef, handleClickOutside)
    const [index, setIndex] = useState(0);

    const [showCredits, setShowCredits] = useState(false);

    const customRenderItem = (item: any, props: any) => <item.type {...item.props} {...props} />;

    const formatCredits = (c: string) => {
        const lines = c.split("\n").filter(l => l && l.replaceAll(" ", "").length > 0).map(l => {
            const parts = l.split(":")
            return [parts.shift(), parts.reduce((acc, curr) => `${acc} ${curr}`, "")]
        })
            .map(parts => `<div class="line"><p class="left">${parts[0]}</p><p class="right">${parts[1]}</p></div>`)
            .join("")
        return `
            <div class="lines">
            ${lines}
            </div>
            `
    }

    const mediaCount = project?.content?.medias?.length || 0;
    const next = () => setIndex(index => ((index + 1) > mediaCount - 1 ? mediaCount - 1 : index + 1))
    const prev = () => setIndex(index => ((index - 1) < 0 ? 0 : index - 1))

    const SlideShow = () => {
        const swipeHandlers: any = useSwipeable({
            onSwipedRight: (eventData: any) => prev(),
            onSwipedLeft: (eventData: any) => next(),
        });

        const [loading, setLoading] = useState(true);


        const medias = project?.content?.medias || [];
        const [isPlaying, setIsPlaying] = useState<any>(null);
        const mobile = useMediaQuery(device.xs)
        return (
            <div id="SwiperContainer">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    centeredSlides={true}
                    centeredSlidesBounds={true}
                    pagination={{ clickable: true }}
                    navigation
                    modules={[Navigation, Pagination,]}
                    // roundLengths={true}
                    // loop={true}
                    className="mySwiper"
                    onSlideChange={() => {
                        setIsPlaying(null);
                    }}
                    autoplay={false}
                    watchSlidesProgress={true}
                >
                    {medias.map((media) => (
                        <SwiperSlide key={media.id}>
                            {
                                media.type == "media" ?
                                    getMediaType(media.url) == MediaType.PICTURE ? <img src={media.url.replace("localhost", window.location.hostname)} /> : <video controls src={media.url.replace("localhost", window.location.hostname)} autoPlay muted loop />
                                    :
                                    <EmbedSlide
                                        width={mobile ? "calc(100vw - 60px)" : "70vw"}
                                        url={media.url}
                                        isSelected={isPlaying === media.id}
                                    />
                            }
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

        );
    }

    const MemoSlideShow = useMemo(() => SlideShow, [project])

    return <StyledProject>
        <div id="container" ref={projectContainerRef}>

            <div className="header">
                <div className="author">{project?.content?.author} </div>
                <div className="title">{project?.content?.name}</div>
                <img className="close" onClick={handleClickOutside} src="/images/close.svg" />
            </div>

            <MemoSlideShow />

            {showCredits && <div
                onClick={(e) => {
                    // @ts-ignore
                    if (e.target.classList.contains("creditsText")) {
                        setShowCredits(false);
                    }
                }}
                className="creditsText"
                dangerouslySetInnerHTML={{ __html: formatCredits(project?.content?.credits || "") }}>
            </div>}
            <div className="credits" onClick={() => setShowCredits(!showCredits)}>CREDITS</div>
        </div>
    </StyledProject >
}

export default React.memo(Project);

