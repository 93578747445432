import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STORAGE_URL } from ".";
import { setCountries } from "./actions/settings-actions";
import { getCountries } from "./reducers/settings-reducer";
import { IcnbadCountry } from "./types";

export const updateMap = (dispatch: Function, countries: IcnbadCountry[]) => {
  fetch(`${STORAGE_URL}/countries`)
    .then(response => response.json())
    .then(countriesConfigs => {
      const updatedCountries = countries.map((country) => {
        const countryConfig = countriesConfigs.find((c: any) => c._id == country._id)
        return {
          ...country, ...{
            content: { ...country.content, ...countryConfig.content },
            picture: {
              rotation: countryConfig.rotation,
              x: countryConfig.x,
              y: countryConfig.y,
              width: countryConfig.width,
              height: countryConfig.height,
              url: countryConfig.image_path
            },

          }
        };
      });
      dispatch(setCountries(updatedCountries));
    })
    .catch(error => console.log('error', error));

}
const UpdateMap = () => {
  const dispatch = useDispatch();
  const countries = useSelector(getCountries);

  useEffect(() => {
    updateMap(dispatch, countries)
  }, [])
  return <></>
}

export default UpdateMap;