import { Button } from '@mui/material'
import { useRef } from 'react'
import Dropzone, { IDropzoneProps, ILayoutProps } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { BottomSheet } from 'react-spring-bottom-sheet'
import styled from 'styled-components'
import { useOnClickOutside, useWindowSize } from 'usehooks-ts'
import { STORAGE_URL } from '../..'
import { setFileUploaderOpen } from '../../actions/settings-actions'
import { getIsFileUploaderOpen } from '../../reducers/settings-reducer'

const StyledUploader = styled.div`
    /* position: absolute; */
    height: 80vh;
    left: 0;
    width: 100%;
    .dzu-dropzone {
        width: 100%;
        height: 100%;
        border: none;
        /* position: absolute; */
        /* bottom: 0; */
        /* background: white; */
        overflow: auto;
        padding-bottom: 3rem;

        &.dropzoneActive {
            background: red;
        }
    }
    .dzu-submitButtonContainer {
        position: fixed;
        bottom: 0;
        margin-left: auto;
        right: calc(5% + 24px);
    }

    .dzu-inputLabelWithFiles {
        position: fixed;
        bottom: 24px;
        z-index: 1;
    }
`

const FileUploader = () => {
    const isFileUploaderOpen = useSelector(getIsFileUploaderOpen);
    const dispatch = useDispatch();

    const getUploadParams = () => { return { url: `${STORAGE_URL}/media` } }
    const handleChangeStatus: IDropzoneProps['onChangeStatus'] = ({ meta, file }, status) => { console.log("XXXXX", status, meta, file) }

    const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
        onDismiss();
    }

    function onDismiss() {
        dispatch(setFileUploaderOpen(false));
    }

    const { width, height } = useWindowSize();

    console.log("OPENHEIGHT", isFileUploaderOpen, height)



    return (
        <BottomSheet
            blocking={false}
            // maxHeight={height * 0.8}
            open={isFileUploaderOpen}
            // open={true}
            onDismiss={onDismiss}
        >

            <StyledUploader>
                <Dropzone
                    getUploadParams={getUploadParams}
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    // autoUpload={false}
                    inputWithFilesContent="Ajouter"
                    submitButtonContent="Valider"
                    inputContent={<p style={{textAlign: "center"}}>Ajoutez des image à la gallerie <br />En cliquant ou déposant des images</p>}
                    accept="image/*,video/*"
                />
            </StyledUploader >
        </BottomSheet>
    )

};

export default FileUploader