import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { devices } from '../media-queries';

const Wrapper = styled.main`
    z-index: 99;
    width: 100%;
    background-color: #ffffffe8;
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    /* cursor: url(/images/left_arrow.cur), auto;
    cursor: url('/images/left_arrow.cur') 0 17, auto;
    cursor: -webkit-image-set(
        url('/images/left_arrow.cur') 1x,
        url('/images/left_arrow.cur') 2x
    ) 0 17, auto; */

    #container {
        cursor: default;
        display: flex;
        overflow: initial;
        ${devices.tablet} {
            flex-flow: column;
            width: 60vw;

            margin: auto;
            max-height: calc(100vh + -2rem);
            overflow: auto;
        }
        ${devices.mobile} {
            flex-flow: column;
            width: 90vw;

            margin: auto;
            max-height: calc(100vh + -2rem);
            overflow: auto;
        }
        position: relative;    
        flex-flow: row;
        padding: 1rem;
        gap: 1rem;
        align-items: center;

        img {
            width: 40%; 
            aspect-ratio: 1;
            object-fit: cover;
            ${devices.tablet} {
                width: 100%;                
            }
            ${devices.mobile} {
                max-width: 100%;
                width: 40vh;
                height: 40vh;
            }

        }
        #text {
            width: 60%;
            padding-right: 1rem;
            overflow: scroll;
            ${devices.tablet} {
                width: 100%;
                padding-right: 0;
                overflow: initial;
            }
            max-height: 80vh;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            justify-content: space-between;
            p {
                text-transform: uppercase;
                margin: 0;
                font-weight: 600;
                font-family: Neue Haas Grotesk Display Pro;
                font-size: 1.7vw;
                ${devices.tablet} {
                    font-size: 2vw;
                    line-height: 3vw;
                    max-height: unset;
                }
                ${devices.mobile} {
                    font-size: 4vw;
                    line-height: 6.5vw;
                    max-height: unset;
                }
                line-height: 2rem;
                text-align: justify;
            }
        } 

        

    }

`

const Biography = (props: any) => {

    const navigate = useNavigate();
    return <Wrapper
        onClick={() => {
            navigate('/')
        }}
    >
        <div id="container" {...props} onClick={(e) => e.stopPropagation()}>
            <img id="image" src="/bio/carine-et-olivier.png" alt="Photo de Carine Charaire et Olivier Casamayou" />
            <div id="text">
                <p>
                    I COULD NEVER BE A DANCER is an international, Paris-based duo of
                    choreographers who are also creative directors and filmmakers. Carine Charaire and
                    Olivier Casamayou come from contemporary dance that they « exported » to the
                    fields of fashion, music, contemporary art and advertising.
                </p>
                <p>
                    Their first experience in the world of fashion began with a shooting that brought
                    together Karl Lagerfeld as photographer and Emmanuelle Alt as stylist, for Adidas.
                    This initiatory experience paved the way to thriving collaborations and led to
                    numerous events, runway shows, photo and video campaigns with high-end fashion
                    brands such as Chanel, Dior, Kenzo, Cartier, Issey Miyake, and Givenchy. A very
                    special and valuable relationship started with Hermès in 2008. Since then, they have
                    been collaborating on various thrilling projects worldwide.
                </p>
                <p>
                    Admittedly, their work also takes part in the making of pop culture through music
                    videos, live performances on stage or television. They have choreographed for
                    numerous iconic artists, including Chaka Khan for her music video "Like Sugar” and
                    her live performances at The Ellen DeGeneres Show and The Graham Norton Show.
                    They have also worked with Kylie Minogue, Stromae, Metronomy, Pet Shop Boys,
                    Yelle, and Mirwais. They regularly collaborate with Jessie Ware as choreographers,
                    filmmakers, and creative directors on various projects: her 2021/2022 "What's Your
                    Pleasure" world tour, several music videos from the same album, her performance at
                    the 2022 Fashion Awards.
                </p>
                <p>
                    Many brands also rely on them for special projects, like the film direction and
                    choreography they did for Air France's safety instructions, which were broadcasted
                    on all flights from 2015 to 2020 or the films they choreographed for the Japanese
                    brand Pocari Sweat, which won multiple awards and had a strong impact in the
                    country. One of these films, featuring 5000 Japanese students dancing, was listed as a
                    Guinness World Record.
                </p>
                <p>
                    Besides their commercial work, Carine and Olivier also carry out constant
                    performance research in major cultural institutions. They collaborated with Valérie
                    Belin on the iconic sacrificial figure of Michael Jackson at the Centre Pompidou in
                    Paris in 2013. They also created PePi, a performance programmed in the first Picasso
                    exhibition in China at the Ullens Centre for Contemporary Art in Beijing in 2019.
                    Their ongoing commitment and questioning are also at the origin of protean projects,
                    like the Babydisco installation at the Palais de Tokyo in 2009 - a disco for children
                    aged 3 to 7! – which became a cultural and media phenomenon. The Villa Noailles, in
                    Hyères, where Babydisco was born, has provided the duo with many possibilities of
                    experimentations bringing together the fields they are passionate about – fashion, art
                    and pop culture.
                </p>

            </div>
        </div>

    </Wrapper>
}


export default Biography;