import {
    EditMode,
    SET_EDIT_MODE,
    SET_FILE_UPLOADER_OPEN,
    SET_GALLERY_PICKER_OPEN,
    SET_SELECTED_PICTURE_MAP_URL,
    SET_SELECTED_PICTURE_MAP_URL_FOR_INTEGRATION,
    SET_COUNTRIES,
    SET_EDIT_COUNTRY_SETTINGS,
    SET_OPEN_PROJECT_ID,
    SET_HOVERED_PROJECT_ID,
} from '../actions/settings-actions';
import { IcnbadCountry, IEditCountrySettings } from '../types';

export default function settingsReducer(state = {}, { type, payload }: any) {
    switch (type) {
        case SET_EDIT_MODE:
            return {
                ...state,
                editMode: payload
            }
        case SET_OPEN_PROJECT_ID:
            return {
                ...state,
                openProjectId: payload
            }
        case SET_FILE_UPLOADER_OPEN:
            return {
                ...state,
                isFileUploaderOpen: payload
            }
        case SET_GALLERY_PICKER_OPEN:
            return {
                ...state,
                isGalleryPickerOpen: payload
            }
        case SET_SELECTED_PICTURE_MAP_URL:
            return {
                ...state,
                selectedPictureMapUrl: payload
            }
        case SET_SELECTED_PICTURE_MAP_URL_FOR_INTEGRATION:
            return {
                ...state,
                selectedPictureMapUrlForIntegration: payload
            }
        case SET_COUNTRIES:
            return {
                ...state,
                countries: payload
            }
        case SET_EDIT_COUNTRY_SETTINGS:
            return {
                ...state,
                editCountrySettings: payload
            }
        case SET_HOVERED_PROJECT_ID:
            return {
                ...state,
                hoveredProjectId: payload
            }
        default:
            return state;
    }
}

// selectors
export function getEditMode(state: any) {
    return state.settingsReducer.editMode as EditMode;
}

export function getIsFileUploaderOpen(state: any) {
    return state.settingsReducer.isFileUploaderOpen as boolean;
}

export function getIsGalleryPickerOpen(state: any) {
    return state.settingsReducer.isGalleryPickerOpen as boolean;
}

export function getSelectedPictureMapUrl(state: any) {
    return state.settingsReducer.selectedPictureMapUrl as string;
}

export function getSelectedPictureMapUrlForIntegration(state: any) {
    return state.settingsReducer.selectedPictureMapUrlForIntegration as string;
}

export function getCountries(state: any) {
    return state.settingsReducer.countries as IcnbadCountry[];
}

export function getEditCountrySettings(state: any) {
    return state.settingsReducer.editCountrySettings as IEditCountrySettings | null;
}

export function getOpenProjectId(state: any) {
    return state.settingsReducer.openProjectId as string | null;
}

export function getHoveredProjectId(state: any) {
    return state.settingsReducer.hoveredProjectId as string | null;
}
