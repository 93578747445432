import { useEffect, useMemo, useState } from 'react';
import { useElementSize, useWindowSize } from 'usehooks-ts'
import { Stage } from 'react-konva';
import { mapSize } from '../../config';
import { IcnbadCountry, IcnbadCategory, IEditCountrySettings } from '../../types';
import styled from "styled-components";
import { useMatch, useNavigate } from "react-router-dom";
import EditCountry from '../EditCountry';
import CreateSvg from '../CreateSvg';
import { Provider, ReactReduxContext, useDispatch, useSelector } from 'react-redux';
import { getCountries, getEditCountrySettings, getEditMode, getSelectedPictureMapUrl } from '../../reducers/settings-reducer';
import { EditMode, setCountries, setEditCountrySettings, setEditMode, setFileUploaderOpen, setGalleryPickerOpen, setSelectedPictureMapUrl } from '../../actions/settings-actions';
import { Button, ButtonGroup } from '@mui/material';
import FileUploader from './FileUploader';
import { STORAGE_URL } from '../..';
import GalleryPicker from './GalleryPicker';

const MapWrapper = styled.div<{ width: number, height: number }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
  align-self: center;
  margin-top: auto;
  margin-bottom: auto;
`

const minScale = 0.3;
const maxScale = 1;

const EditableMap = () => {
    const dispatch = useDispatch();
    // const [selected, setSelected] = useState("3188ac83-b5b8-516b-88be-021e8da32e38")
    const [selected, setSelected] = useState("")
    const editMode = useSelector(getEditMode);

    const countries = useSelector(getCountries);

    // const [squareRef, { width: windowWidth, height: windowHeight }] = useElementSize()
    const { width: windowWidth, height: windowHeight } = useWindowSize()



    const mapWidth = mapSize.width;
    const mapHeight = mapSize.height;

    const scaleW = Math.max(minScale, Math.min((windowWidth) / mapSize.width, maxScale))
    const scaleH = Math.max(minScale, Math.min((windowHeight) / mapSize.height, maxScale))
    const scale = Math.max(scaleW, scaleH);

    const canvasOffsetHeight = Math.max((windowHeight - mapHeight) / 2, 0);
    const canvasOffsetWidth = Math.max((windowWidth - mapWidth) / 2, 0);

    const editCountrySettings = useSelector(getEditCountrySettings);

    const updateCountry = (props: { country: IcnbadCountry, settings: IEditCountrySettings | null, }) => {
        if (!props.settings) return;
        const { rotation, x, y, width, height, image_path, content } = props.settings;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            rotation,
            x,
            y,
            width,
            height,
            image_path,
            content
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };



        fetch(`${STORAGE_URL}/countries/${props.country._id}`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));

        dispatch(setEditMode(EditMode.SELECTION))
        dispatch(setCountries(countries.map((country) => {
            if (country._id == props.country._id)
                return {
                    ...country, ...{
                        picture: {
                            rotation,
                            x,
                            y,
                            width,
                            height,
                            url: image_path
                        }
                    }
                }
            else return country;
        })));
    }

    const selectedCountry = countries.find(c => c._id == selected);
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/admin/${selected}`)
        dispatch(setEditCountrySettings(null))
    }, [selected])


    return (
        <>
            <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            >
                {useMemo(() =>
                    <MapWrapper style={{ transform: `scale(${scale})` }} width={mapWidth} height={mapHeight}>

                        {/* STATIC PICTURES */}

                        <div style={{
                            zIndex: editMode == EditMode.SELECTION ? 1 : -1,
                            opacity: editMode == EditMode.SELECTION ? 1 : 0.5,
                            filter: `grayscale(${editMode == EditMode.SELECTION ? 0 : 1})`,
                        }}>
                            {countries
                                .filter(country => editMode == EditMode.SELECTION || country._id != selected)
                                .map(country => {
                                    return <CreateSvg
                                        country={country}
                                        key={country._id}
                                    />
                                })
                            }
                        </div>

                        {/* EDITABLE */}
                        {editMode == EditMode.TRANSFORM ? <ReactReduxContext.Consumer>
                            {({ store }) => (
                                <Stage
                                    height={mapHeight + canvasOffsetHeight * 2}
                                    width={mapWidth + canvasOffsetWidth * 2}
                                    style={{
                                        marginTop: `-${canvasOffsetHeight}px`,
                                        marginLeft: `${-canvasOffsetWidth}px`,
                                        // zIndex: editMode == EditMode.SELECTION ? -1 : 1,
                                    }}>
                                    <Provider store={store}>

                                        {selectedCountry && <EditCountry
                                            key={selectedCountry._id}
                                            country={selectedCountry}
                                            offset={{ x: canvasOffsetWidth, y: canvasOffsetHeight }}
                                        />
                                        }
                                    </Provider>

                                </Stage>
                            )}
                        </ReactReduxContext.Consumer> : (
                            selectedCountry ?
                                <CreateSvg
                                    country={selectedCountry}
                                    key={selectedCountry._id}
                                /> : <></>
                        )
                        }

                        {editMode == EditMode.SELECTION && countries
                            .map(country => {
                                const isSelected = country._id == selected

                                return (
                                    <svg
                                        key={`svg_${country._id}`}
                                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
                                        style={{
                                            opacity: '0',
                                            zIndex: 1,
                                            position: "absolute",
                                            pointerEvents: "none",
                                            top: `${country.y}px`,
                                            left: `${country.x}px`,
                                            width: `${country.width}px`,
                                            height: `${country.width}px`,
                                        }}

                                    >
                                        <g
                                            onClick={() => {
                                                setSelected(country._id);
                                                dispatch(setSelectedPictureMapUrl(country.picture.url))
                                                dispatch(setEditMode(EditMode.TRANSFORM))
                                            }}
                                            cursor="pointer" pointerEvents="auto" fill={isSelected ? "red" : "blue"}>

                                            <path
                                                d={country.maskPath} />
                                        </g>
                                    </svg>
                                )
                            })
                        }

                    </MapWrapper>
                    , [windowWidth, windowHeight, selected, countries, editMode])}


                <ButtonGroup style={{ padding: '0.5rem', boxSizing: 'border-box', position: 'absolute', top: '1rem', zIndex: '1', background: "white" }} variant="outlined" aria-label="outlined button group">

                    {selectedCountry && editCountrySettings != null &&
                        <Button fullWidth variant="contained"
                            onClick={() => {
                                setSelected("")
                                updateCountry({ settings: editCountrySettings, country: selectedCountry })
                                dispatch(setEditMode(EditMode.SELECTION))
                            }}
                        >
                            Appliquer
                        </Button>}

                    {selectedCountry &&
                        <Button fullWidth onClick={() => {
                            dispatch(setGalleryPickerOpen(true))
                        }}>Changer d'image</Button>
                    }

                    {selectedCountry &&
                        <Button fullWidth onClick={() => {
                            dispatch(setEditMode(EditMode.EDIT_CONTENT))
                        }}>Modifier le contenu</Button>
                    }

                    {selectedCountry && <Button
                        fullWidth
                        onClick={() => {
                            setSelected("")
                            dispatch(setEditMode(EditMode.SELECTION))
                        }}
                    >
                        Deselectionner
                    </Button>}

                </ButtonGroup>

                <ButtonGroup style={{ padding: '0.5rem', boxSizing: 'border-box', position: 'absolute', bottom: '1rem', zIndex: '1', background: "white" }} variant="outlined" aria-label="outlined button group">
                    <Button style={{ marginBottom: "1rem" }} variant="outlined" onClick={() => {
                        dispatch(setFileUploaderOpen(true))
                    }}>Ajouter des image</Button>

                </ButtonGroup>

            </div >
           {editMode != EditMode.EDIT_CONTENT &&  <GalleryPicker />}
        </>
    );
};
export default EditableMap;

