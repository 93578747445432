// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
}

html,
body,
#root {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-x: hidden;
}


.react-transform-wrapper {
    z-index: 0 !important;
    height: 100% !important;
    width: 100% !important;
    position: absolute !important;
    top: 0 !important;
    margin-top: 2rem !important;
}

.react-transform-component {
    width: unset !important;
    height: unset !important;
    justify-content: center !important;
    align-items: center !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;;;IAGI,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,kBAAkB;AACtB;;;AAGA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;IACtB,6BAA6B;IAC7B,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;IACxB,kCAAkC;IAClC,8BAA8B;AAClC","sourcesContent":["body {\n    margin: 0;\n}\n\nhtml,\nbody,\n#root {\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n    overflow-x: hidden;\n}\n\n\n.react-transform-wrapper {\n    z-index: 0 !important;\n    height: 100% !important;\n    width: 100% !important;\n    position: absolute !important;\n    top: 0 !important;\n    margin-top: 2rem !important;\n}\n\n.react-transform-component {\n    width: unset !important;\n    height: unset !important;\n    justify-content: center !important;\n    align-items: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
