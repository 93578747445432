import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import Masonry from 'react-masonry-css'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useFetch, useOnClickOutside, useWindowSize } from 'usehooks-ts'
import { setGalleryPickerOpen, setSelectedPictureMapUrl, setSelectedPictureMapUrlForIntegration } from '../../actions/settings-actions'
import { getIsGalleryPickerOpen, getSelectedPictureMapUrl, getSelectedPictureMapUrlForIntegration } from '../../reducers/settings-reducer'
import { IMAGES_EXTENSIONS, VIDEO_EXTENSIONS } from '../../config'
import { STORAGE_URL } from '../..'
import { TextField } from '@mui/material'

const StyledGalleryPicker = styled.div`

    /* width: 90%;
    max-height: 60%; */
    height: max-content;
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 5%; */
    background: white;
    overflow: auto;
    padding-bottom: 3rem;
    z-index: 1000;

    
    .my-masonry-grid {
        display: -webkit-box; /* Not needed if autoprefixing */
        display: -ms-flexbox; /* Not needed if autoprefixing */
        display: flex;
        margin-left: -1rem; /* gutter size offset */
        width: auto;
        padding: 1rem;
    }
    .my-masonry-grid_column {
        padding-left: 1rem; /* gutter size */
        background-clip: padding-box;
        display: flex;
        flex-flow: column;
        gap: 1rem;
    }


    .media {
        width: 100%;
        outline: 1px solid black;
        cursor: pointer;

        video,
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &.active {
            outline: 10px solid green;
        }
    }

`

const GalleryPicker = (props: { forIntegration?: boolean }) => {
    const isGalleryPickerOpen = useSelector(getIsGalleryPickerOpen);
    const { data: medias, error } = useFetch<string[]>(`${STORAGE_URL}/media`);
    const { forIntegration } = props;

    const setSelectedPicture = (url: string) => {
        console.log("setSelectedPicture", forIntegration, url)
        if (forIntegration == true) {
            dispatch(setSelectedPictureMapUrlForIntegration(url))
        } else {
            dispatch(setSelectedPictureMapUrl(url))
        }
    }
    const selectedPicture = useSelector(forIntegration ? getSelectedPictureMapUrlForIntegration : getSelectedPictureMapUrl);
    // const [selected, setSelected] = useState("");

    const dispatch = useDispatch();



    useEffect(() => {
        console.log("GALLERY PICKER MOUNT")
    }, [])

    const sheetRef = useRef<any>()



    function onDismiss() {
        dispatch(setGalleryPickerOpen(false));
    }

    useEffect(() => {
    }, [])

    const [name, setName] = useState('Cat in the Hat');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const { width, height } = useWindowSize();

    const [search, setSearch] = useState("");

    return (
        <BottomSheet
            blocking={false}
            maxHeight={height * 0.8}
            open={isGalleryPickerOpen}
            onDismiss={onDismiss}
            ref={sheetRef}
        >
            {/* <h1>INTEGRATION = {forIntegration ? "OUI":"NON"}</h1> */}
            <div style={{ width: '100%', boxSizing: 'border-box', position: 'fixed', top: '0', zIndex: '9999999', background: 'white', padding: '1rem', marginTop: '1rem' }}>
                <TextField style={{ width: "100%" }} onChange={(e) => setSearch(e.target.value)} value={search} id="outlined-basic" label="Rechercher" variant="outlined" />
            </div>
            <StyledGalleryPicker>
                {/* <button onClick={handleClose}>X</button> */}
                <Masonry
                    breakpointCols={3}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {/* array of JSX items */}
                    {medias?.map((path, i) => {
                        const extension = path.split(".").pop() || "";
                        const url = `${STORAGE_URL}/uploads/${path}`;
                        const visible = url.toLowerCase().includes(search.toLowerCase());
                        console.log("VISIBLEEEEEE", visible, url);
                        const active = url == selectedPicture;
                        if (IMAGES_EXTENSIONS.includes(extension)) {
                            return (
                                <div
                                    style={{ display: visible ? "block" : "none" }}
                                    onClick={() => setSelectedPicture(url)}
                                    className={`media image ${active ? "active" : ""}`}>
                                    <img src={url} key={i} />
                                </div>
                            )
                        } else if (VIDEO_EXTENSIONS.includes(extension)) {
                            return (
                                <div
                                    style={{ display: visible ? "block" : "none" }}
                                    onClick={() => setSelectedPicture(url)}
                                    className={`media video ${active ? "active" : ""}`}>
                                    <video src={url} autoPlay loop muted key={i} />
                                </div>
                            )
                        }
                    }
                    )}
                </Masonry>
            </StyledGalleryPicker >
        </BottomSheet >
    )
}

export default GalleryPicker