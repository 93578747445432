import { Box } from 'konva/lib/shapes/Transformer';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Group, Image, Layer, Rect, Shape, Transformer } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import useImage from 'use-image';
import { useWindowSize } from 'usehooks-ts';
import { EditMode, setCountries, setEditCountrySettings, setEditMode, setGalleryPickerOpen } from '../actions/settings-actions';
import { getCountries, getSelectedPictureMapUrl } from '../reducers/settings-reducer';
import { IcnbadCountry, IEditCountrySettings } from '../types';


const EditCountry = (props: { country: IcnbadCountry, offset: { x: number, y: number } }) => {
    const selectedMapPictureUrl = useSelector(getSelectedPictureMapUrl);

    const dispatch = useDispatch();
    
    const [image] = useImage(selectedMapPictureUrl);
    const [mask] = useImage(props.country.source);
    const imageRef = useRef<any>();
    const maskRef = useRef<any>();
    const transformerRef = useRef<any>();
    const {width: windowWidth, height: windowHeight} = useWindowSize()
    
    const scale = 1;
    
    
    useEffect(() => {
        dispatch(setGalleryPickerOpen(false));
        console.log("selectedMapPictureUrl", selectedMapPictureUrl)
    }, [selectedMapPictureUrl])



    useEffect(() => {
        // we need to attach transformer manually
        transformerRef.current.nodes([imageRef.current]);
        transformerRef.current.getLayer().batchDraw();
    }, [image]);

    useLayoutEffect(() => {
        if (image) {
            const width = image.naturalWidth;
            const height = image.naturalHeight;
            if (!width || !height) return;


            if (image.src == props.country.picture.url) {
                imageRef.current.setWidth(props.country.picture.width)
                imageRef.current.setHeight(props.country.picture.height)
                imageRef.current.setRotation(props.country.picture.rotation)
                imageRef.current.setPosition({ x: props.country.picture.x, y: props.country.picture.y })
            } else {
                const ratio = height / width;
                const wantedWidth = props.country.width * 1.3;
                imageRef.current.setWidth(wantedWidth);
                imageRef.current.setHeight(wantedWidth * ratio);
                imageRef.current.setPosition({ x: 0, y: 0 })

                dispatch(setEditCountrySettings({
                    rotation: 0,
                    width: wantedWidth,
                    height: wantedWidth * ratio,
                    x: 0,
                    y: 0,
                    // content: { categories: [], author: "authorrr", credits: "creditsss", name: "namee" },
                    image_path: image.src,
                }))

            }
            imageRef.current.getLayer().batchDraw()

        }

    }, [image])

    useLayoutEffect(() => {
        imageRef.current.cache();
    }, [imageRef, image]);

    useLayoutEffect(() => {
        maskRef.current.cache();
    }, [maskRef, mask]);

    return (
        <Layer>
            <Group
                x={props.country.x + props.offset.x}
                y={props.country.y + props.offset.y}
            >
                <Image
                    draggable={true}
                    image={image}
                    ref={imageRef}
                    x={0}
                    y={0}
                    scale={{ x: scale, y: scale }}
                    onDragStart={() => {
                    }}
                />

                <Image image={mask}
                    listening={false}
                    globalCompositeOperation={"destination-in"}
                    ref={maskRef}

                    scale={{ x: scale, y: scale }}
                />

            </Group >
            <Transformer
                ref={transformerRef}
                onDragEnd={(e) => {
                    dispatch(setEditCountrySettings({
                        rotation: e.target.getAbsoluteRotation(),
                        width: e.target.width(),
                        height: e.target.height(),
                        x: e.target.getPosition().x - props.country.x - props.offset.x,
                        y: e.target.getPosition().y - props.country.y - props.offset.y,
                        // content: { categories: [], author: "authorrr", credits: "creditsss", name: "namee" },
                        image_path: selectedMapPictureUrl,
                    }))
                }}
                onTransformEnd={(e) => {
                    dispatch(setEditCountrySettings({
                        rotation: e.currentTarget.getAbsoluteRotation(),
                        width: e.currentTarget.width(),
                        height: e.currentTarget.height(),
                        x: e.currentTarget.getPosition().x - props.country.x - props.offset.x,
                        y: e.currentTarget.getPosition().y - props.country.y - props.offset.y,
                        // content: { categories: [], author: "authorrr", credits: "creditsss", name: "namee" },
                        image_path: selectedMapPictureUrl,
                    }))

                }}
            />
        </Layer>

    );
}

export default EditCountry;