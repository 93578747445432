import { useEffect, useState } from 'react';
import { useElementSize, useMediaQuery, useWindowSize } from 'usehooks-ts'
import { Stage } from 'react-konva';
import { mapSize } from '../config';
import { IcnbadCountry, IcnbadCategory } from '../types';
import styled from "styled-components";
import { useMatch, useNavigate } from "react-router-dom";
import EditCountry from './EditCountry';
import CreateSvg from './CreateSvg';
import { setCountries, setHoveredProjectId } from '../actions/settings-actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCountries, getHoveredProjectId } from '../reducers/settings-reducer';
import { device } from '../media-queries';

// comme on peut pas avoir 30 canvas (apparament performance issue)
// on fait en sorte que seulement l'image selectionnee soit un Konva !

const MapWrapper = styled.div<{ width: number, height: number }>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  position: relative;
  align-self: center;
  margin-left: -80px;
  margin-top: auto;
  margin-bottom: auto;
`

const minScale = 0.3;
const maxScale = 1;

let mouseX = -1;
let mouseY = -1;

const Map = (props: any) => {
  const [selected, setSelected] = useState("")
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const mobile = useMediaQuery(device.xs)
  const hoveredProjectId = useSelector(getHoveredProjectId)


  const mapWidth = mapSize.width;
  const mapHeight = mapSize.height;

  const scaleW = Math.max(minScale, Math.min((windowWidth) / mapSize.width, maxScale))
  const scaleH = Math.max(minScale, Math.min((windowHeight) / mapSize.height, maxScale))
  const scale = Math.min(scaleW, scaleH);


  const isCategoryChoregraphy = useMatch("/choregraphy");
  const isCategoryCreativeDirection = useMatch("/creative_direction");
  const isCategoryVideoMaking = useMatch("/video_making");

  const activeCategories = [
    ...(isCategoryChoregraphy ? ["Choreography"] : []),
    ...(isCategoryCreativeDirection ? ["CreativeDirection"] : []),
    ...(isCategoryVideoMaking ? ["VideoMaking"] : []),
  ]

  useEffect(() => {
    window.addEventListener('mousedown', (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
    })
  }, [])

  const isCategoryActive = (country: IcnbadCountry) => activeCategories.length == 0 ? true : country.content.categories.some(c => activeCategories.includes(c))
  const countries = useSelector(getCountries);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.resetTransform)
      props.resetTransform();
  }, [])


  return (
    <div style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      ...(props?.style || {})
    }}>

      <MapWrapper style={{ zoom: scale, overflow: 'hidden', bottom: "80px" }} width={mapWidth} height={mapHeight}>

        {/* STATIC DISABLED PICTURES */}
        <div style={{
          opacity: 0.3,
          filter: "grayscale(1)",
          zIndex: 0,
        }}>

          {countries
            .filter(country => !isCategoryActive(country))
            .map(country => {
              return <CreateSvg
                country={country}
                key={country._id}
                style={{
                  pointerEvents: "none",
                  // // filter: active ? "drop-shadow(0 0 0.75rem black)" : "grayscale(1)",
                  // // zIndex: active ? 999 : "unset",
                  // // transform: active ? "scale(1.05)" : "",
                  // // transition: "transform 0.1s"
                }}
              />
            })
          }
        </div>

        {/* STATIC ACTIVE PICTURES */}
        {countries
          .filter(country => isCategoryActive(country))
          .map(country => {
            return <CreateSvg
              country={country}
              key={country._id}
              style={{
                pointerEvents: "none",
                // // filter: active ? "drop-shadow(0 0 0.75rem black)" : "grayscale(1)",
                // // zIndex: active ? 999 : "unset",
                // // transform: active ? "scale(1.05)" : "",
                // // transition: "transform 0.1s"
              }}
            />
          })
        }
        {/* CLICK EVENTS */}
        {countries
          .map(country => {
            const isSelected = country._id == selected

            return (
              <svg

                version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve"
                style={{
                  opacity: '0',
                  position: "absolute",
                  pointerEvents: "none",
                  top: `${country.y}px`,
                  left: `${country.x}px`,
                  width: `${country.width}px`,
                  height: `${country.height}px`,
                }}

              >
                <g
                  onClick={(e) => {
                    const hasMoved = e.clientX != mouseX && e.clientY != mouseY;
                    if (hasMoved) return;
                    if (mobile) {
                      if (country._id != hoveredProjectId) {
                        return dispatch(setHoveredProjectId(country._id))
                      } else {
                        setSelected(country._id);
                        navigate(`/project/${country._id}`)
                      }
                    } else {
                      setSelected(country._id);
                      navigate(`/project/${country._id}`)
                    }
                  }}
                  onMouseOver={(e) => {
                    if (!mobile) {
                      dispatch(setHoveredProjectId(country._id));
                    }
                  }}
                  onMouseLeave={(e) => {
                    // dispatch(setHoveredProjectId(null));
                  }}
                  cursor="pointer" pointerEvents="auto" fill={isSelected ? "red" : "blue"}>

                  <path
                    d={country.maskPath} />
                </g>
              </svg>
            )
          })
        }


      </MapWrapper>
    </div>

  );
};

export default Map;

