import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './components/App';
import Biography from './components/Biography';
import Map from './components/Map';
import Admin from './components/Admin';
import { applyMiddleware, combineReducers, configureStore, createStore } from '@reduxjs/toolkit';
import settingsReducer from './reducers/settings-reducer';
import { EditMode } from './actions/settings-actions';
import ReactDOM from 'react-dom';
import { countries } from './config';
import UpdateMap from './UpdateMap';

export const STORAGE_URL = process.env.REACT_APP_STORAGE_URL;
// export const STORAGE_URL = "/storage";
const container = document.getElementById('root')!;
console.log("STORAGE_URL", STORAGE_URL)


const allReducers = combineReducers({
  settingsReducer: settingsReducer,
});



const store = configureStore(
  {
    reducer: allReducers,
    preloadedState: {
      settingsReducer: {
        editMode: EditMode.SELECTION,
        isFileUploaderOpen: false,
        isGalleryPickerOpen: false,
        selectedPictureMapUrl: "http://4.bp.blogspot.com/-LIdQkeTkYiE/UcyO2LCsnfI/AAAAAAAAFJw/hqb_Tn2x0CE/s1600/mire.png",
        selectedPictureMapUrlForIntegration: "http://4.bp.blogspot.com/-LIdQkeTkYiE/UcyO2LCsnfI/AAAAAAAAFJw/hqb_Tn2x0CE/s1600/mire.png",
        countries: countries,
        openProjectId: null,
        hoveredProjectId: null,
      }
    }
  }
);

const isAdmin = !!eval(process.env.REACT_APP_IS_ADMIN || "");
console.log("ADMIN", isAdmin)
ReactDOM.render(<React.StrictMode>
  <Provider store={store}>
    <UpdateMap />
    <HashRouter>
      <Routes>
        {isAdmin ? <>
          <Route path="/*" element={<Admin />} />
          <Route path="/admin/*" element={<Admin />} />
        </> : <>
          {/* <Route path="/admin/*" element={<Admin />} /> */}
          <Route path="/*" element={<App />} />
        </>}
      </Routes>
    </HashRouter>
  </Provider>

</React.StrictMode>
  , container);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
