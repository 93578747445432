// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useMatch } from "react-router-dom";
import { Divider, FormLabel, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Paper, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// @ts-ignore
import { SortableContainer, SortableElement, arrayMove, SortableContainerProps, SortableElementProps, SortableHandle } from 'react-sortable-hoc';
import React from 'react';
import { EditMode, setEditMode, setGalleryPickerOpen } from '../../actions/settings-actions';
import { useDispatch } from 'react-redux';
import { useWindowSize } from 'usehooks-ts';
import { devices } from '../../media-queries';
import { useSelector } from 'react-redux'
import { getCountries, getEditMode, getSelectedPictureMapUrl, getSelectedPictureMapUrlForIntegration } from '../../reducers/settings-reducer';
import { IcnbadCategory, IcnbadCountry, IEditCountryContent, IEditCountrySettings } from '../../types';
import { getMediaType, MediaType } from '../../config';
import { EmbedSlide } from '../Project';
import { STORAGE_URL } from '../..';
import GalleryPicker from './GalleryPicker';
import { updateMap } from '../../UpdateMap';


const Wrapper = styled.div`

    #container {
        display: flex;
        justify-content: center;
        flex-flow: column;
        max-width: 800px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;


        padding: 1rem;
        box-sizing: border-box;

        display: flex;
        flex-flow: row;
        ${devices.mobile} {
            flex-flow: column;
        }

        .row {
            display: flex;
            flex-flow: column;
        }

        .divider {
            display: none;
        }

        #add_media {
            margin-bottom: 1rem;
            box-sizing: border-box;
            width: calc(100% - 3rem);
            align-self: flex-start;
            ${devices.mobile} {
                width: calc(100% - 6rem);
                align-self: center;
                margin-top: 3rem;
            }
        }
        ${devices.mobile} {
            .divider {
                display: block;
            }
        }

        gap: 2rem;

        > div {
            width: 100%;

            ul {
                padding: 0;
                margin :0;
                padding-bottom: 2rem;

                li {
                    position: relative;
                }
            }
        }
    }

    #submit {
        position: absolute;
        bottom: 1rem;
        background: white;
        margin-left: 50%;
        transform: translateX(-50%);
        z-index: 99999;
    }
`

const Item = styled.li`
    z-index: 10000 !important;
    margin-bottom: 1rem;
    /* background: red; */
    list-style: none;

    ${devices.mobile} {
        margin-left: 3rem;
    }
    margin-right: 3rem;
    
    display: flex;
    flex-flow: column;
    
    .media {
        width: 100%;
        video,
        img {
            height: 8rem;
            width: 100%;
            object-fit: contain;
        }
    }
`


interface IcnbadMedia {
    type: string,
    url: string,
    id: string,
}



const MediaList = (props: { items: IcnbadMedia[], setItems: Function }) => {

    const { items, setItems } = props;
    const deleteItem = (id: string) => {
        console.log("DELETE ITEM", id)
        const check = window.confirm("voulez-vous vraiment supprimer cette image ?")
        if (!check) return;
        const newItems = [...items].filter((pic) => pic.id != id)
        setItems(newItems);
    }

    const insertItem = () => {
        const newItems = [{ url: "", id: uuidv4(), type: "unset" }, ...items]
        setItems(newItems)
    }

    const DragHandle = SortableHandle(() => <DragHandleIcon style={{ position: 'absolute', right: '0', cursor: 'pointer', transform: "translateX(100%)", zIndex: 999 }} />);

    const SortableItem: React.ComponentClass<SortableElementProps & { value: IcnbadMedia, index: number, _index: number }, any> = SortableElement(({ value, index, _index }: { value: IcnbadMedia, index: number, _index: number }) => {
        console.log("VALUEINDEX", value, index, _index)
        return (
            <Item>
                <DragHandle />

                <Paper elevation={3}>


                    <div className="media">
                        {{
                            "media": getMediaType(value.url) == MediaType.PICTURE ? <img src={value.url} /> : <video src={value.url} autoPlay muted loop />,
                            "embed": <EmbedSlide width={"100%"} url={value.url} />
                        }[value.type]}
                    </div>
                    <ButtonGroup fullWidth style={{ padding: '0.5rem', boxSizing: 'border-box' }} variant="outlined" aria-label="outlined button group">
                        <Button fullWidth onClick={() => {
                            dispatch(setGalleryPickerOpen(true))
                            setSelectedMedia(value.id)
                        }}>Gallerie</Button>
                        <Button fullWidth onClick={() => {
                            setEmbedUrlOpen(true)
                            setSelectedMedia(value.id)
                        }} >Intégration </Button>
                        <Button onClick={() => { console.log("BEGIN DELETE", value.id); deleteItem(value.id) }} fullWidth startIcon={<DeleteIcon color="error" />}></Button>
                    </ButtonGroup>
                </Paper>
            </Item>
        )
    });


    const SortableList: React.ComponentClass<SortableContainerProps & { items: IcnbadMedia[] }, any> = SortableContainer(({ items }: { items: IcnbadMedia[] }) => {
        return (
            <ul>
                {items.map((value: any, index: number) => (
                    <SortableItem key={`item-${index}`} index={index} _index={index} value={value} />
                ))}
            </ul>
        );
    })


    const closeEmbedUrl = () => setEmbedUrlOpen(false);
    const EmbedUrlPopup = () => {
        const [url, setUrl] = useState("")
        return (
            <Dialog open={embedUrlOpen} onClose={closeEmbedUrl}>
                <DialogTitle>Lien d'intégration</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Insérez un lien d'intégration Youtube ou Viméo
                    </DialogContentText>
                    <TextField
                        onChange={(e) => setUrl(e.target.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Url"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeEmbedUrl}>Annuler</Button>
                    <Button onClick={() => {
                        closeEmbedUrl();
                        setItems([...items].map(i => i.id == selectedMedia ? { ...i, type: "embed", url: url } : i))
                    }}>Appliquer</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const [embedUrlOpen, setEmbedUrlOpen] = useState(false);


    useEffect(() => { console.log("DID MOUNT OK") }, [])
    useEffect(() => { console.log("ITEMS UPDATE", items.map(i => i.id)) }, [items])
    const selectedPictureMapUrlForIntegration = useSelector(getSelectedPictureMapUrlForIntegration);

    // when image picker changes, we update the selected item with the new image url
    useEffect(() => {
        setItems([...items].map(i => i.id == selectedMedia ? { ...i, type: "media", url: selectedPictureMapUrlForIntegration } : i))
        dispatch(setGalleryPickerOpen(false));
    }, [selectedPictureMapUrlForIntegration])

    const [selectedMedia, setSelectedMedia] = useState<null | string>(null);

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        console.log("ON SORT END", oldIndex, newIndex)
        if (oldIndex == newIndex) return;
        console.log("ON SORT END DID PASS", oldIndex, newIndex)
        setItems(arrayMove(items, oldIndex, newIndex))
    }
    const dispatch = useDispatch();
    return <>
        <Button id="add_media" variant="outlined" onClick={insertItem}>Ajouter</Button>
        <EmbedUrlPopup />
        <SortableList useDragHandle helperClass='sortableHelper' items={items} onSortEnd={onSortEnd} />
    </>

}


const EditCountryContent = () => {
    const dispatch = useDispatch();
    const sheetRef = useRef<any>()
    const editMode = useSelector(getEditMode);

    const open = editMode == EditMode.EDIT_CONTENT;

    function onDismiss() {
        dispatch(setEditMode(EditMode.TRANSFORM));
    }

    useEffect(() => {
        // setOpen(true)
    }, [])

    const match = useMatch('/admin/:projectId');
    const projectId = match?.params.projectId;

    const projects = useSelector(getCountries);
    const project = projects.find(p => p._id == projectId);

    const [author, setAuthor] = useState(project?.content?.author || "")
    const [name, setName] = useState(project?.content?.name || "")
    const [credits, setCredits] = useState(project?.content?.credits || "")
    const [items, setItems] = useState<IcnbadMedia[]>(project?.content?.medias || []);

    const [categories, setCategories] = useState<IcnbadCategory[]>(project?.content?.categories || [])



    const { height } = useWindowSize();




    const updateCountry = () => {
        if (!projectId) return;
        const data: Partial<IEditCountrySettings> = {
            content: {
                categories: categories.filter(function (elem: IcnbadCategory, index, self) {
                    return index === self.indexOf(elem);
                }),
                author,
                name,
                credits,
                medias: items,
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(data);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
        };



        fetch(`${STORAGE_URL}/countries/${projectId}`, requestOptions)
            .then(response => response.text())
            .then(result => {
                console.log(result)
                updateMap(dispatch, projects)
            })
            .catch(error => console.log('error', error));
        // dispatch(setEditMode(EditMode.SELECTION))
    }



    const setCategorieActive = (categorie: IcnbadCategory, checked: boolean) => {
        if (checked == true)
            setCategories([...categories, categorie])
        else
            setCategories([...categories].filter(c => c != categorie))
    }

    useEffect(() => {
        console.log("setCategorieActive", categories)
    }, [categories])


    return <>
        <BottomSheet
            maxHeight={height * 0.9}
            open={open}
            blocking={false}
            onDismiss={onDismiss}
            ref={sheetRef}
        >
            <Wrapper>
                {/* <p>{projectId}</p> */}

                <div id="container">
                    <div className="row">

                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 2, marginLeft: 0, marginRight: 0 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField
                                autoFocus={false}
                                fullWidth
                                label="Auteur"
                                value={author}
                                onChange={(e) => setAuthor(e.target.value)}
                            />
                            <TextField
                                autoFocus={false}
                                fullWidth
                                label="Nom"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                autoFocus={false}
                                fullWidth
                                label="Crédits"
                                multiline
                                rows={10}
                                value={credits}
                                onChange={(e) => setCredits(e.target.value)}
                            />
                        </Box>

                        <FormLabel component="legend"><p style={{ fontFamily: "Roboto" }}>Catégories</p></FormLabel>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={categories.includes("Choreography")} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCategorieActive("Choreography", event.target.checked)} />} label="Choreography" />
                            <FormControlLabel control={<Checkbox checked={categories.includes("CreativeDirection")} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCategorieActive("CreativeDirection", event.target.checked)} />} label="CreativeDirection" />
                            <FormControlLabel control={<Checkbox checked={categories.includes("VideoMaking")} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCategorieActive("VideoMaking", event.target.checked)} />} label="VideoMaking" />
                        </FormGroup>

                    </div>

                    <div className="row">
                        <FormLabel component="legend"><p style={{ fontFamily: "Roboto" }}>Médias</p></FormLabel>
                        {useMemo(() => <MediaList items={items} setItems={setItems} />, [items])}

                    </div>

                </div>
                <Button id="submit" onClick={() => {

                    onDismiss();
                    updateCountry();

                }} variant="outlined" size="large" color="error" startIcon={<DoneIcon />}>
                    Valider
                </Button>
            </Wrapper>

        </BottomSheet>
        {editMode == EditMode.EDIT_CONTENT && <GalleryPicker forIntegration={true} />}
    </>
}

export default EditCountryContent;