import * as React from "react"
import styled from "styled-components";
// // @ts-ignore
// import ReactFreezeframe from 'react-freezeframe';
// // @ts-ignore
// import { Freeze } from 'freezeframe/types'
import { IcnbadCountry } from "../types"


const StyledContainer = styled.div<{ country: IcnbadCountry }>`
    position: absolute;
    top: ${props => props.country.y}px;
    left: ${props => props.country.x}px;
    width: ${props => props.country.width}px;
    height: ${props => props.country.height}px;

    .container{
        position: absolute;
        clip-path: path('${props => props.country.maskPath}');
        width: 100%;
        height: 100%;

        transform: scale(1.02);
        /* transform: scale(1.1); */
        img {
            /* transform: rotate(-48.680622deg) scale(0.8508659475670841);
            transform-origin: top left;
            position: absolute; */
            /* width: 557px;
            height: 340px; */
            /* top: 148px;
            left: -69.2122626990546px; */
            pointer-events: none;
        }
    }

`

const CreateSvg = (props: { country: IcnbadCountry, style?: any }) => {

    return (
        <StyledContainer
            country={props.country}
            style={props?.style}
        >
            <div
                className="container"
            >
                <img src={props.country.picture.url.replace("localhost", window.location.hostname)} alt="" style={{
                    transform: `translate(${props.country.picture.x}px, ${props.country.picture.y}px) rotate(${props.country.picture.rotation}deg)`,
                    // top: `${props.country.picture.y}px`,
                    // left: `${props.country.picture.x}px`,
                    width: `${props.country.picture.width}px`,
                    height: `${props.country.picture.height}px`,
                    transformOrigin: "top left",
                    position: "absolute",
                }} />
                {/* {props.country._id % 2 == 0 ? <ReactFreezeframe src="https://c.tenor.com/fWXyb86dSWMAAAAM/ok-cat.gif" /> : } */}
            </div>
        </StyledContainer>
    )
}

export default CreateSvg
