import { IcnbadCountry, IEditCountrySettings } from "../types";

export const SET_EDIT_MODE = 'settings:set_edit_mode';
export const SET_FILE_UPLOADER_OPEN = 'settings:set_file_uploader_open';
export const SET_GALLERY_PICKER_OPEN = 'settings:set_gallery_picker_open';
export const SET_SELECTED_PICTURE_MAP_URL = 'settings:set_selected_picture_map_url';
export const SET_SELECTED_PICTURE_MAP_URL_FOR_INTEGRATION = 'settings:SET_SELECTED_PICTURE_MAP_URL_FOR_INTEGRATION';
export const SET_COUNTRIES = 'settings:set_countries';
export const SET_EDIT_COUNTRY_SETTINGS = 'settings:set_edit_country_settings';
export const SET_OPEN_PROJECT_ID = 'settings:set_open_project_id';
export const SET_HOVERED_PROJECT_ID = 'settings:set_hovered_project_id';

export enum EditMode {
  SELECTION = "selection",
  TRANSFORM = "transform",
  EDIT_CONTENT = "edit_content"
}

export function setEditMode(editMode: EditMode) {
  return {
    type: SET_EDIT_MODE,
    payload: editMode
  };
}

export function setFileUploaderOpen(open: boolean) {
  return {
    type: SET_FILE_UPLOADER_OPEN,
    payload: open
  };
}

export function setGalleryPickerOpen(open: boolean) {
  return {
    type: SET_GALLERY_PICKER_OPEN,
    payload: open
  };
}

export function setSelectedPictureMapUrl(url: string) {
  return {
    type: SET_SELECTED_PICTURE_MAP_URL,
    payload: url
  };
}

export function setSelectedPictureMapUrlForIntegration(url: string) {
  return {
    type: SET_SELECTED_PICTURE_MAP_URL_FOR_INTEGRATION,
    payload: url
  };
}

export function setCountries(countries: IcnbadCountry[]) {
  return {
    type: SET_COUNTRIES,
    payload: countries
  };
}

export function setEditCountrySettings(editCountrySettings: Partial<IEditCountrySettings> | null) {
  return {
    type: SET_EDIT_COUNTRY_SETTINGS,
    payload: editCountrySettings
  };
}

export function setOpenProjectId(openProjectId: string | null) {
  return {
    type: SET_OPEN_PROJECT_ID,
    payload: openProjectId
  };
}


export function setHoveredProjectId(hoveredProjectId: string | null) {
  return {
    type: SET_HOVERED_PROJECT_ID,
    payload: hoveredProjectId
  };
}

