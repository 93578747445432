import React, { useState } from 'react';
import EditableMap from './EditableMap';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getEditMode } from '../../reducers/settings-reducer';
import { EditMode, setEditMode, setSelectedPictureMapUrl } from '../../actions/settings-actions';
import FileUploader from './FileUploader';
import GalleryPicker from './GalleryPicker';
import EditCountryContent from './EditCountryContent';
import Project from '../Project';
import { useMatch } from 'react-router-dom';


const AdminWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
`



const Admin = () => {
    const dispatch = useDispatch();

    const previewCard = false;
    const match = useMatch('/admin/:projectId');
    const projectId = match?.params.projectId;


    return (
        <AdminWrapper id="adminwrapper">
            <EditableMap />
            {previewCard && <Project projectId="test" />}
            {/* // content: { categories: [], author: "authorrr", credits: "creditsss", name: "namee" }, */}
            {match && <EditCountryContent />}

            <FileUploader />
        </AdminWrapper>

    )
}

export default Admin;